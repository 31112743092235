.centralizar {
  display: flex;
  flex-direction: column;
  margin-top: 5%;
}

.centralizar-row {
  justify-content: center;
  align-items: center;
}

.card-border {
  border: 1px solid rgb(84, 43, 102) !important;
}

.front {
  width: 400px;
  height: 400px;
  background-position: center center;
}

.card-titulo {
  padding: 10px 5px;
}

.card {
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 5px;
}
.card .card-header {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.overflow-visible {
  overflow: visible;
}

.inputDateTime {
  border-radius: 5px !important;
  font-size: 16px;
  padding: 0.375rem 0.75rem;
  border: 1px solid #ced4da;
}
