.centralizar {
    display: flex;
    flex-direction: column;
    margin-top: 5%;
}

.centralizar-row {
    justify-content: center;
    align-items: center
}

.card-effect {
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0px 0px 10px #b9a7cb;
    border: none !important;
}

.card-effect:hover:after{
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    position: absolute;
    background: inherit;
    background-color: rgba(255,255,255,0.1);
    transition: background-color ease 1s;
}

.card-effect:hover {
    box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)
}

.image-card {
    height: 100px;
    background-color: #861414;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.icon-card {
    background-color: #861414;
    height: 100px;
    display: grid;
    place-items: center;
}