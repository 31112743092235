$theme-colors: (
  "primary": #843434,
  "dark": #861414
);
$border-radius: 1px;
$border-radius-lg: 1px;
$border-radius-sm: 1px;

.template-header h3{
  color: #212529;
}

@import "~bootstrap/scss/bootstrap";

